import { styled } from "@enfusion-ui/web-core";
import { kebabCase } from "lodash";
import * as React from "react";
// This import path is required for jest
import { Button } from "../control";
import { createControlled } from "../forms/ControlledInputs";
import { InputLabelControl } from "../inputs/TextInput";
import { StyledButtonGroup } from "./StyledButtonGroup";
const Wrapper = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const DEFAULT_DISABLE_DESELECT = false;
const getBackgroundColor = (props) => {
    const { $selectedColor = "primary", $selected, disabled } = props;
    if ($selected && disabled)
        return "var(--background-color-2)";
    return `var(--${$selected ? kebabCase($selectedColor) : "input-background"})`;
};
const getTextColor = (props) => {
    const { theme, $selectedColor = "primary", $selected, disabled } = props;
    return $selected &&
        $selectedColor !== "primary" &&
        !disabled &&
        ["darkColorBlind", "light", "dracula", "darkCarbon"].includes(theme.key)
        ? "var(--text-inverted)"
        : "var(--text-normal)";
};
const getHoverBackgroundColor = (props) => {
    const { $selectedColor = "primary", disabled, $selected, theme } = props;
    if ($selected && disabled)
        return ["darkColorBlind", "light"].includes(theme.key)
            ? "var(--background-color-1-hover)"
            : "var(--background-color-2)";
    return $selected ? `var(--${$selectedColor})` : "var(--input-background)";
};
const DisconnectedButtonGroup = styled.div `
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
`;
export const SelectButton = styled(Button) `
  background-color: ${(props) => getBackgroundColor(props)};
  color: ${(props) => getTextColor(props)};
  border: 1px solid transparent;

  :hover,
  :disabled,
  :disabled:hover {
    color: ${(props) => props?.$selected ? getTextColor(props) : "var(--text-muted)"};
    background-color: ${(props) => getHoverBackgroundColor(props)};
    opacity: ${({ disabled }) => (disabled ? 1 : 0.8)};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
`;
export function ButtonGroupSelect({ selectedValue, options, onSelect, disableDeselect = DEFAULT_DISABLE_DESELECT, title, disabled, disconnected, groupId, style, ...rest }) {
    const handleClick = (value) => {
        const isDeselect = Object.is(value, selectedValue);
        if (!(isDeselect && disableDeselect)) {
            onSelect(isDeselect ? null : value);
        }
    };
    const ButtonGroupWrapper = disconnected
        ? DisconnectedButtonGroup
        : StyledButtonGroup;
    return (React.createElement(Wrapper, { style: style, id: groupId },
        React.createElement(InputLabelControl, { label: title }),
        React.createElement(ButtonGroupWrapper, { ...rest }, options?.map(({ label, title, value, selectedColor, display, }) => (React.createElement(SelectButton, { key: label, onClick: () => handleClick(value), title: title || label, "$selectedColor": selectedColor, "$selected": Object.is(value, selectedValue), type: "button", disabled: disabled, className: "select-btn", dataTestId: kebabCase(`select-btn-${value}`), "data-selected": Object.is(value, selectedValue) }, display ? display : label))))));
}
export const ControlledButtonGroupSelect = createControlled()(ButtonGroupSelect, ({ value, onChange, ...props }) => ({
    ...props,
    onSelect: onChange,
    selectedValue: value,
    neutralSeparatorColor: true,
    disableDeselect: true,
}));
