import ClipboardJS from "clipboard";
import * as React from "react";
import { useLatest } from "react-use";
export function copyToKeyboardAction({ getText, onSuccess, onError, }) {
    const handleSuccess = () => {
        onSuccess?.();
    };
    const handleError = () => {
        onError?.();
    };
    navigator.clipboard
        .writeText(getText())
        .then(handleSuccess)
        .catch(handleError);
}
export const CopyToClipboard = ({ component, getText, onSuccess, onError, }) => {
    const compRef = React.useRef();
    const getTextRef = useLatest(getText);
    const onSuccessRef = useLatest(onSuccess);
    const onErrorRef = useLatest(onError);
    const handleSuccess = (_e) => {
        onSuccessRef.current?.();
    };
    const handleError = (e) => {
        onErrorRef.current?.(e);
    };
    React.useEffect(() => {
        let clipboard;
        if (compRef.current) {
            clipboard = new ClipboardJS(compRef.current, {
                text: () => getTextRef.current(),
            });
            clipboard.on("success", handleSuccess);
            clipboard.on("error", handleError);
        }
        return () => {
            clipboard?.destroy();
        };
    }, []);
    return React.cloneElement(component, { ref: compRef });
};
