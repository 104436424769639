/* eslint-disable @typescript-eslint/no-explicit-any */
import { PORTFOLIO_REBALANCE_LABEL_MAP, } from "@enfusion-ui/types";
import * as React from "react";
export const OBJECT_WORDING = {
    Portfolios: {
        upper: "Workbench",
        upperPlural: "Workbenches",
        lower: "workbench",
        lowerPlural: "workbenches",
        work: "rebalancing",
    },
    Dashboards: {
        upper: "Dashboard",
        upperPlural: "Dashboards",
        lower: "dashboard",
        lowerPlural: "dashboards",
        work: "changes",
    },
    Reconciliation: {
        upper: "Reconciliation settings",
        upperPlural: "Reconciliation settings",
        lower: "reconciliation settings",
        lowerPlural: "reconciliation settings",
        work: "changes",
    },
    Services: {
        upper: "Services file",
        upperPlural: "Services files",
        lower: "services file",
        lowerPlural: "services files",
        work: "changes",
    },
    Operations: {
        upper: "Operation",
        upperPlural: "Operations",
        lower: "operation",
        lowerPlural: "operations",
        work: "changes",
    },
    Reports: {
        upper: "Report",
        upperPlural: "Reports",
        lower: "report",
        lowerPlural: "reports",
        work: "changes",
    },
    GeneralFiles: {
        upper: "File",
        upperPlural: "Files",
        lower: "file",
        lowerPlural: "files",
        work: "changes",
    },
    WatchLists: {
        upper: "Watch list",
        upperPlural: "Watch lists",
        lower: "watch list",
        lowerPlural: "watch lists",
        work: "changes",
    },
    Folder: {
        upper: "Folder",
        upperPlural: "Folders",
        lower: "folder",
        lowerPlural: "folders",
        work: "changes",
    },
    Password: {
        upper: "Your password",
        upperPlural: "Your passwords",
        lower: "your password",
        lowerPlural: "your passwords",
        work: "changes",
    },
    Order: {
        upper: "Order",
        upperPlural: "Orders",
        lower: "order",
        lowerPlural: "orders",
        work: "changes",
    },
    Compliance: {
        upper: "Compliance",
        upperPlural: "Compliance",
        lower: "compliance",
        lowerPlural: "compliance",
        work: "changes",
    },
    ManagedServices: {
        upper: "Managed services",
        upperPlural: "Managed services",
        lower: "managed services",
        lowerPlural: "managed services",
        work: "changes",
    },
};
const toastActionContent = (w, past, present) => ({
    success: `${w.upper} ${past} successfully`,
    failure: `Failed to ${present} ${w.lower}`,
});
function createExplorerActionStatusTexts(section) {
    const wording = OBJECT_WORDING[section];
    return {
        save: toastActionContent(wording, "saved", "save"),
        create: toastActionContent(wording, "created", "create"),
        rename: toastActionContent(wording, "renamed", "rename"),
        copy: toastActionContent(wording, "copied", "copy"),
        move: toastActionContent(wording, "moved", "move"),
        delete: toastActionContent(wording, "deleted", "delete"),
        share: {
            success: `Copied ${wording.lower} share link`,
            failure: `Failed to copy ${wording.lower} share link`,
            linkSuccess: `Shared ${wording.lower} link`,
        },
        deepLink: {
            success: `Opened ${wording.lower} deep link`,
            failure: `Failed to open ${wording.lower} deep link`,
            notValid: `Not a valid ${wording.lower} deep link`,
        },
        openAdditionalInstance: {
            success: `Opened an additional ${wording.lower} instance`,
            failure: `Failed opening an additional ${wording.lower} instance`,
        },
    };
}
export const TOAST_CONTENT = {
    Reports: createExplorerActionStatusTexts("Reports"),
    Services: createExplorerActionStatusTexts("Services"),
    Operations: createExplorerActionStatusTexts("Operations"),
    Portfolios: {
        ...createExplorerActionStatusTexts("Portfolios"),
        tourError: "Tour canceled because workbench failed to load",
    },
    Dashboards: createExplorerActionStatusTexts("Dashboards"),
    Reconciliation: createExplorerActionStatusTexts("Reconciliation"),
    GeneralFiles: createExplorerActionStatusTexts("GeneralFiles"),
    WatchLists: createExplorerActionStatusTexts("WatchLists"),
    folder: createExplorerActionStatusTexts("Folder"),
    ManagedServices: createExplorerActionStatusTexts("ManagedServices"),
    password: {
        change: toastActionContent(OBJECT_WORDING.Password, "was changed", "change"),
        networkError: {
            fetchFailed: "Failed to get password rules",
        },
    },
    oems: {
        newOrder: toastActionContent(OBJECT_WORDING.Order, "staged", "stage"),
        split: toastActionContent(OBJECT_WORDING.Order, "split", "split"),
        fill: toastActionContent(OBJECT_WORDING.Order, "fill added", "add fill"),
        cancel: toastActionContent(OBJECT_WORDING.Order, "cancelled", "cancel"),
        transmit: toastActionContent(OBJECT_WORDING.Order, "transmitted", "transmit"),
        tourError: "Tour canceled because OMS failed to load",
        compliance: {
            ...toastActionContent(OBJECT_WORDING.Compliance, "ran", "run"),
            warn: "Compliance completed with warnings",
            default: "Compliance state unchanged",
        },
        networkError: {
            holidayCenter: {
                fetchFailed: "Failed to get holiday center",
            },
            dayCounts: {
                fetchFailed: "Failed to get day counts",
            },
            varianceNotional: {
                fetchFailed: "Failed to get calculated variance notional",
            },
            priceLimit: {
                fetchFailed: "Failed to get limit price",
            },
            complianceStatus: {
                fetchFailed: "Failed to update compliance status",
            },
            complianceRecords: {
                fetchFailed: "Failed to get compliance records",
            },
            order: {
                fetchFailed: (orderId) => `Order(${orderId}) failed fetch details`,
            },
        },
    },
};
const editableDesc = (desc) => `Editable. ${desc}`;
const editablePWBDescriptions = {
    finalExposure: "Pro-forma exposure. Current Ex plus Order Ex plus Inc Ex.",
    incrementalExposure: "Proposed orders exposure.",
    finalExposurePercentNAV: "Final Ex divided by the denominator set in the settings.",
    incrementalExposurePercentNAV: "Inc Ex divided by the denominator set in the settings.",
    finalNMV: "Pro-forma market value. Current MV plus Inc MV.",
    incrementalNMV: "Incremental market value. Final MV minus Current MV.",
    finalNAV: "Pro-forma NAV. Current NAV plus Inc NAV.",
    incrementalNAV: "Amount of any modeled subscriptions or redemptions. Final NAV minus Current NAV.",
    finalQuantity: "Pro-forma quantity. Current Qty plus Order Qty plus Inc Qty.",
    incrementalQuantity: "Proposed orders quantity.",
    finalCTD: "Final Contribution to Duration",
    incrementalCTD: "Incremental Contribution to Duration",
    finalDuration: "Final Weighted Duration",
    incrementalDuration: "Incremental Weighted Duration",
};
export const pwbColumnDescriptions = {
    ...Object.entries(editablePWBDescriptions).reduce((res, e) => ({ ...res, [e[0]]: editableDesc(e[1]) }), {}),
    currentCTD: "Current contribution to Duration for positions and orders.",
    benchmarkCTD: "Benchmark contribution to Duration.",
    modelCTD: "Model contribution to Duration.",
    duration: "Duration of the held or ordered instrument. Weighted average when aggregated.",
    benchmarkDuration: "Duration of the instrument held by the benchmark. Weighted average when aggregated.",
    modelDuration: "Duration of the instrument held by the model. Weighted average when aggregated.",
    currentExposure: "Position exposure defined by the numerator aggregation set in the settings.",
    currentOrderExposure: "Order exposure defined by the numerator aggregation set in the settings.",
    benchmarkExposure: "The exposure of the instrument associated with the corresponding benchmark(s) defined by the numerator aggregation set in the settings.",
    modelExposure: "The exposure of the instrument associated with the corresponding model(s) defined by the numerator aggregation set in the settings.",
    benchmarkExposureDifference: "The difference between the Final Exposure and the Benchmark Exposure.",
    modelExposureDifference: "The difference between the Final Exposure and the Model Exposure.",
    currentExposurePercentNAV: "Ex divided by the denominator set in the settings.",
    currentOrderExposurePercentNAV: "Exposure of the order as a percentage of NAV.",
    benchmarkExposurePercentNAV: "The total weight of the instrument associated with the corresponding benchmark or blend of multiple benchmarks as a percent.",
    modelExposurePercentNAV: "The total weight of the instrument associated with the corresponding model or blend of multiple models as a percent.",
    benchmarkPercentDifference: "The difference between the Final Exposure % NAV and the Benchmark Exposure % NAV. Also known as Active Weight.",
    modelPercentDifference: "The difference between the Final Exposure % NAV and the Model Exposure % NAV. Also known as Drift Versus Target",
    currentQuantity: "Quantity of the position.",
    currentOrderQuantity: "Quantity of the order.",
    benchmarkQuantity: "The notional quantity of the instrument associated with the corresponding benchmark or blend of multiple benchmarks.",
    modelQuantity: "The notional quantity of the instrument associated with the corresponding model or blend of multiple models.",
    benchmarkQuantityDifference: "The difference between the Final Quantity and the Benchmark Quantity.",
    modelQuantityDifference: "The difference between the Final Quantity and the Model Quantity.",
    currentMNV: "Local market value displayed in the fund's base currency. This not impacted by the settings.",
    currentNAV: "Current net asset value calculated using the denominator set in the settings.",
    benchmarkName: "The name of the associated benchmark or blend of multiple benchmarks.",
    modelName: "Name of the associated model portfolio or blend of multiple model portfolios.",
    country: "Country of the security.",
    riskCountry: "Country of the security to which it is predominantly exposed.",
    assetClass: "Group of similar instrument types (i.e, equity and fixed income).",
    instrumentCcy: "Currency in which a given instrument is denominated.",
    instrumentSubtype: "Subgroup of instrument types (e.g., corporate bonds and government bonds).",
    instrument: "Primary method of grouping instruments together at Enfusion (e.g., equity and bond).",
    price: "Editable. Price used to calculate exposure.",
    issuer: "Entity that issued a given security.",
    account: "Custodian account.",
    fund: "The long name of the fund.",
    fundShortName: "The short name or code of the fund.",
    sector: "The highest level group in the GICS global industry classification standard (e.g., Financials).",
    industry: "The third-highest grouping level in the GICS global industry classification standard (e.g., Capital Markets & Financial Services).",
    industryGroup: "The second-highest grouping level in the GICS global industry classification standard (e.g., Banks & Insurance).",
    subIndustry: "The most granular group in the GICS global industry classification standard (e.g., Life Insurance vs. Property & Casualty Insurance).",
    bbYellow: "The conventional Bloomberg symbol, which is a combination of the exchange ticker plus the countries exchange code plus the asset class. For example, Apple Inc is AAPL US because it is the US composite of all exchanges.",
    cusip: "The CUSIP number is a unique identification number assigned to all stocks and registered bonds in the United States and Canada; it is used to create a concrete distinction between securities that are traded on public markets.",
    isin: "International Securities Identification Numbering system is the global ISO standard for unique identification of financial and referential instruments, including equity, debt, derivatives, and indices.",
    sedol: "The Stock Exchange Daily Official List, which is a list of security identifiers; this field is empty unless licensed from LSEG.",
    dirtyNMV: "The dirty accounting market value of the position and order.",
    cleanNMV: "The accounting market value of the position and order.",
};
export const CHANGE_TRADE_INSTRUCTIONS = {
    header: "For one or more destinations, select",
    texts: [
        "Send to queue trade(s) for sending",
        "Suppress current and future changes",
        "One time suppress",
        "Leave blank for default action",
    ],
};
export const PNL_ADJUSTMENT_INSTRUCTIONS = {
    header: "To utilize the P&L Adjustment,",
    texts: [
        "A trade type of 'P&L Adjustment' is required",
        "One (and only one) trade fee of either 'P&L Realized Adjustment' or 'P&L Unrlzd and Rlzd Adjustment' is required",
        "A trading account associated with a general ledger must be defined",
    ],
    externalLink: "https://zendesk.enfusionsystems.com/hc/en-us/articles/4411989068951",
};
const SUPPORT_MAIL_SUBJECT = {
    permission: "Issue with permissions",
    missing: "Issue with file could not be found.",
    forbidden: "Issue with forbidden access.",
};
export function replaceNewLinesForMail(text, encode = "web") {
    return encode === "web"
        ? encodeURIComponent(text)
        : text.replaceAll("\n", "\r\n");
}
export const SUPPORT_MAIL_CONTENT = (component, msg, when, appSource, version, meta = "", encode = "web") => replaceNewLinesForMail(`Hello,

I am seeing an issue in a ${component} view.

The message shown is:
"${msg}"

Some helpful details:
Time: ${when.toISOString()}
App: ${appSource}
Version: ${version}${meta
    ? `
${meta}`
    : ""}

Thanks`, encode);
export const ERROR_VIEW_CONTENT = {
    permission: (component) => `You do not have permission to access the ${component} view.`,
    missing: (component) => `${component} file could not be found.`,
    contactSupport: (link) => (React.createElement(React.Fragment, null,
        "Please contact ",
        link,
        " if you believe this is an error.")),
    forbidden: (component) => `This ${component} file's access is forbidden.`,
    supportMail: (type, component, when, appSource, version, meta, encode) => [
        SUPPORT_MAIL_SUBJECT[type],
        SUPPORT_MAIL_CONTENT(component, ERROR_VIEW_CONTENT[type](component), when, appSource, version, meta, encode),
    ],
    pwbActionErrorSupportMail: (msg, meta) => {
        return [
            "Portfolio Workbench Action Error",
            SUPPORT_MAIL_CONTENT("Portfolio Workbench", `Error: ${msg.lineOne}${msg.lineTwo.trim().length > 0
                ? `
Content: ${msg.lineTwo}`
                : ""}`, new Date(), meta.appSource, meta.appVersion, `Path: ${meta.path}`, meta.encode),
        ];
    },
};
export const cashBreakTypes = {
    CashActivity: "Cash Activity",
    CashBalance: "Cash Balance",
};
export const FORM_INPUTS_CONTENT = {
    PWB: {
        settings: {
            deMinimis: {
                title: "De Minimis",
                relativeRebalance: {
                    title: "Relative rebalance settings",
                    labels: {
                        percentDifferenceFromModel: "% Difference from Model",
                        modelAbsentFromPortfolio: "Do not Rebalance if Model Member is Absent from Portfolio",
                        percentDifferenceFromBenchmark: "% Difference from Benchmark",
                        portfolioAbsentFromModel: "Do not Rebalance if Portfolio Member is Absent from Model",
                    },
                    tooltips: {
                        percentDifferenceFromModel: "Define the minimum diff between Model and Portfolio at which to rebalance an instrument.",
                        percentDifferenceFromBenchmark: "Define the minimum diff between Benchmark and Portfolio at which to rebalance an instrument.",
                    },
                },
                absoluteRebalance: {
                    title: "Absolute rebalance settings",
                    labels: {
                        minimumModelPercentExposure: "Minimum Model % Exposure",
                        minimumBenchmarkPercentExposure: "Minimum Benchmark % Exposure",
                    },
                    tooltips: {
                        minimumModelPercentExposure: "Define the minimum Model weight at which to rebalance an instrument.",
                        minimumBenchmarkPercentExposure: "Define the minimum Benchmark weight at which to rebalance an instrument.",
                    },
                },
                order: {
                    title: "Order threshold settings",
                    labels: {
                        deminimusQuantity: "Inc Quantity",
                        deminimusExposure: "Inc Ex",
                        deminimusIncrementalExposurePercent: "Inc Ex % NAV",
                    },
                },
            },
        },
    },
};
function createConfirmationModalTexts(section, options = {}) {
    const wording = OBJECT_WORDING[section];
    return {
        save: {
            title: () => `Save ${wording.upper} Changes?`,
            content: () => `Do you want to save the changes to this ${wording.lower}?`,
        },
        reload: {
            title: () => `Reload ${wording.upper}?`,
            content: () => `Do you want to reload this ${wording.lower}?${options.reloadChangesLost ? ` Any ${wording.work} will be lost.` : ""}`,
            submitButtonText: "Continue",
        },
    };
}
export const CONFIRMATION_MODAL_CONTENT = {
    Portfolios: {
        ...createConfirmationModalTexts("Portfolios", { reloadChangesLost: true }),
        rebalance: {
            title: (cmd) => `${cmd ? PORTFOLIO_REBALANCE_LABEL_MAP[cmd] : ""} Rebalance?`,
            content: () => "Do you want to rebalance this workbench?",
        },
    },
    Hierarchy: {
        title: "Confirm Apply Strategy Book to Hierarchies",
        content: () => (React.createElement(React.Fragment, null,
            "One or more Books assigned to Allocations are not consistent with the Strategy Book assigned to this Order.",
            React.createElement("br", null),
            "Click \"Yes\" to apply Strategy Book to all downstream Allocations and Trades and \"No\" to preserve them")),
        submitButtonText: "Yes",
        cancelButtonText: "No",
        submitActionTheme: "primary",
    },
};
export const COMPLIANCE_ASSOCIATION_CONTENT = {
    ErrorMessages: {
        findRule: "Error finding the rule.",
        findRulePack: "Error finding the rule pack.",
        getInitData: "Failed getting init data.",
        getRulesAndRulePacks: "Failed to get rule(s) and rule pack(s).",
        getDocumentation: "Failed to get a documentation.",
        getRules: "Failed to get rule(s).",
        updateRules: "Failed to update rule(s).",
        moveRules: "Failed to move rule(s).",
        getRulePacks: "Failed to get rule pack(s).",
        createRulePack: "Failed to create a rule pack.",
        updateRulePacks: "Failed to update rule pack(s).",
        deleteRulePacks: "Failed to delete rule pack(s).",
    },
    EditModal: {
        AssociationPanel: {
            Popover: {
                header: "Compliance Rule Associations",
                texts: [
                    "Fund Specific: Applied strictly only to the selected associations",
                    "Firmwide: Applied to all funds/accounts except for selected associations",
                ],
            },
            targetWarning: "Warning: this will apply the selected rules to ALL funds",
            infoText: {
                // "Select a fund and/or portfolio group to associate this rule pack with", // Phase 2
                rulePack: "Select a fund(s) to associate this rule pack with",
                rule: "Select a fund(s) to associate this rule with",
            },
        },
    },
};
export const NEW_PASSWORD_CHANGE_MESSAGE = "New Passwords must be at least 8 characters in length, contain one numeric digit and one upper case character.";
export const MANAGED_SERVICES_CONTENT = {
    ErrorMessages: {
        invalidJobConfig: "Invalid Configuration for some selected job(s)",
        invalidCredential: "Authentication Error",
    },
    SuccessMessage: {
        runningJobs: "Running Selected Job(s)",
    },
};
