export const FINANCIAL_SUBTYPE_OPTIONS = [
    { label: "Equity", value: "Equity" },
    { label: "Currency Pair", value: "CurrencyPair" },
    { label: "Equity Option", value: "EquityOption" },
    { label: "Index", value: "Index" },
    { label: "Commodity", value: "Commodity" },
    { label: "IndexOption", value: "IndexOption" },
    { label: "Commodity Future", value: "CommodityFuture" },
    { label: "Index Future", value: "IndexFuture" },
    { label: "Bond", value: "Bond" },
    { label: "Currency Future", value: "CurrencyFuture" },
    { label: "InterestRate Future", value: "InterestRateFuture" },
    { label: "Bond Future", value: "BondFuture" },
    { label: "Equity Future", value: "EquityFuture" },
    { label: "Bond Future Option", value: "BondFutureOption" },
    { label: "Commodity Future Option", value: "CommodityFutureOption" },
    { label: "Currency Future Option", value: "CurrencyFutureOption" },
    { label: "Equity Future Option", value: "EquityFutureOption" },
    { label: "Index Future Option", value: "IndexFutureOption" },
    { label: "Interest Rate Future Option", value: "InterestRateFutureOption" },
    { label: "Market Rate", value: "MarketRate" },
    { label: "Vanilla InterestRate Swap", value: "VanillaInterestRateSwap" },
    { label: "FXForward", value: "FXForward" },
    { label: "Forward RateAgreement", value: "ForwardRateAgreement" },
    { label: "Vanilla FX Option", value: "VanillaFXOption" },
    {
        label: "Binary One Touch FX Digital Option",
        value: "BinaryOneTouchFXDigitalOption",
    },
    {
        label: "Binary Double One Touch FX Digital Option",
        value: "BinaryDoubleOneTouchFXDigitalOption",
    },
    {
        label: "Binary No Touch FX Digital Option",
        value: "BinaryNoTouchFXDigitalOption",
    },
    {
        label: "Binary Double No Touch FX Digital Option",
        value: "BinaryDoubleNoTouchFXDigitalOption",
    },
    {
        label: "Digital Call FX Digital Option",
        value: "DigitalCallFXDigitalOption",
    },
    {
        label: "Digital Put FX Digital Option",
        value: "DigitalPutFXDigitalOption",
    },
    {
        label: "Digital Range FX Digital Option",
        value: "DigitalRangeFXDigitalOption",
    },
    { label: "FX Option Barrier", value: "FXOptionBarrier" },
    { label: "FX Swap", value: "FXSwap" },
    { label: "FX Spot", value: "FXSpot" },
    { label: "Deposit Rate", value: "DepositRate" },
    { label: "Swap Rate", value: "SwapRate" },
    { label: "Future", value: "Future" },
    { label: "Future Option", value: "FutureOption" },
    { label: "Future Calendar Spread", value: "FutureCalendarSpread" },
    { label: "Warrant", value: "Warrant" },
    { label: "Credit Default Swap", value: "CreditDefaultSwap" },
    { label: "Restricted Equity", value: "RestrictedEquity" },
    { label: "Right", value: "Right" },
    { label: "Preferred", value: "Preferred" },
    { label: "Cash", value: "Cash" },
    { label: "Total Return Swap", value: "TotalReturnSwap" },
    { label: "Libor Rate", value: "LiborRate" },
    { label: "Forward Rate", value: "ForwardRate" },
    { label: "Volatility Rate", value: "VolatilityRate" },
    { label: "Domestic Rate", value: "DomesticRate" },
    { label: "Discount Rate", value: "DiscountRate" },
    { label: "Interest Rate Volatility", value: "InterestRateVolatility" },
    { label: "Bank Loan", value: "BankLoan" },
    { label: "Convertible Bond", value: "ConvertibleBond" },
    { label: "CDS Spread", value: "CDSSpread" },
    { label: "Municipal Bond", value: "MunicipalBond" },
    { label: "Convertible Preferred", value: "ConvertiblePreferred" },
    { label: "Bankers Acceptance Rate", value: "BankersAcceptanceRate" },
    { label: "Treasury Rate", value: "TreasuryRate" },
    { label: "Treasury Bond", value: "TreasuryBond" },
    { label: "CDX", value: "CDX" },
    { label: "Energy Future", value: "EnergyFuture" },
    { label: "Cap Floor", value: "CapFloor" },
    { label: "Cap Floor Volatility", value: "CapFloorVolatility" },
    { label: "Basis Swap", value: "BasisSwap" },
    { label: "Swaption", value: "Swaption" },
    { label: "Swaption Volatility", value: "SwaptionVolatility" },
    { label: "Index Divisor", value: "IndexDivisor" },
    { label: "ADR", value: "ADR" },
    { label: "Variance Swap", value: "VarianceSwap" },
    { label: "Volatility Swap", value: "VolatilitySwap" },
    { label: "FX Digital Option", value: "FXDigitalOption" },
    { label: "Repurchase Agreement", value: "RepurchaseAgreement" },
    { label: "Preferred Bond", value: "PreferredBond" },
    { label: "BRL Interest Rate Swap", value: "BRLInterestRateSwap" },
    { label: "Commodity Forward", value: "CommodityForward" },
    { label: "Commodity Rate", value: "CommodityRate" },
    { label: "Forex Fixing Rate", value: "ForexFixingRate" },
    { label: "Real Estate", value: "RealEstate" },
    { label: "OTC Equity Option", value: "OTCEquityOption" },
    { label: "ABS", value: "ABS" },
    { label: "LCDX", value: "LCDX" },
    { label: "LCDS", value: "LCDS" },
    { label: "FRA Rate", value: "FRARate" },
    { label: "CDS Reference Obligation", value: "CDSReferenceObligation" },
    { label: "Inflation Swap Rate", value: "InflationSwapRate" },
    { label: "Zero Coupon Inflation Swap", value: "ZeroCouponInflationSwap" },
    { label: "Year On Year Inflation Swap", value: "YearOnYearInflationSwap" },
    { label: "OIS Interest Rate Swap", value: "OISInterestRateSwap" },
    { label: "Zero Coupon Swap", value: "ZeroCouponSwap" },
    { label: "Certificate Of Deposit", value: "CertificateOfDeposit" },
    { label: "Stock Loan Borrow", value: "StockLoanBorrow" },
    { label: "Exchange Traded Fund", value: "ExchangeTradedFund" },
    { label: "Basis Swap Spread", value: "BasisSwapSpread" },
    { label: "Muni CDS", value: "MuniCDS" },
    { label: "Future Spread", value: "FutureSpread" },
    { label: "Credit Default Swaption", value: "CreditDefaultSwaption" },
    { label: "OTC Bond Option", value: "OTCBondOption" },
    { label: "Mutual Fund", value: "MutualFund" },
    { label: "Hedge Fund", value: "HedgeFund" },
    { label: "Recovery Rate Swap", value: "RecoveryRateSwap" },
    { label: "OTC Spread Option", value: "OTCSpreadOption" },
    { label: "Swaption Volatility Skew", value: "SwaptionVolatilitySkew" },
    { label: "OTC Outperformance Option", value: "OTCOutperformanceOption" },
    { label: "OTC Worst Of Option", value: "OTCWorstOfOption" },
    { label: "Physical Commodity", value: "PhysicalCommodity" },
    { label: "CDX Tranche", value: "CDXTranche" },
    { label: "REIT", value: "REIT" },
    { label: "MBS", value: "MBS" },
    { label: "FX Spot Forward", value: "FXSpotForward" },
    { label: "Equity Basket", value: "EquityBasket" },
    { label: "Trade Claim", value: "TradeClaim" },
    { label: "Bond Forward", value: "BondForward" },
    { label: "Credit Index Swaption", value: "CreditIndexSwaption" },
    { label: "ASCOT", value: "ASCOT" },
    { label: "OTC Realized Variance Option", value: "OTCRealizedVarianceOption" },
    { label: "Private Equity", value: "PrivateEquity" },
    { label: "Borrow Rate", value: "BorrowRate" },
    { label: "Non Trading Asset", value: "NonTradingAsset" },
    { label: "Non Trading Liability", value: "NonTradingLiability" },
    { label: "Non Trading Income", value: "NonTradingIncome" },
    { label: "Non Trading Expense", value: "NonTradingExpense" },
    { label: "Bond Index", value: "BondIndex" },
    { label: "Collateral Pledge", value: "CollateralPledge" },
    { label: "OTC Flex Option", value: "OTCFlexOption" },
    { label: "Spread Override", value: "SpreadOverride" },
    { label: "Participatory Note", value: "ParticipatoryNote" },
    { label: "Volatility Surface", value: "VolatilitySurface" },
    { label: "Cross Currency OIS", value: "CrossCurrencyOIS" },
    { label: "Credit Facility", value: "CreditFacility" },
    { label: "Funded Revolver", value: "FundedRevolver" },
    { label: "Unfunded Revolver", value: "UnfundedRevolver" },
    { label: "CMBS", value: "CMBS" },
    { label: "CDO", value: "CDO" },
    { label: "CMO", value: "CMO" },
    { label: "Term Loan", value: "TermLoan" },
    { label: "FX Volatility Surface", value: "FXVolatilitySurface" },
    { label: "Equity Forward", value: "EquityForward" },
    { label: "Crypto Currency", value: "CryptoCurrency" },
    { label: "Crypto Spot", value: "CryptoSpot" },
    { label: "Mortgage", value: "Mortgage" },
    { label: "CLO", value: "CLO" },
    { label: "Dual Digital Option", value: "DualDigitalOption" },
    { label: "Dividend Swap", value: "DividendSwap" },
    { label: "Var Vol Volatility Surface", value: "VarVolVolatilitySurface" },
    { label: "Swaption Volatility Surface", value: "SwaptionVolatilitySurface" },
    { label: "CDX Index", value: "CDXIndex" },
    { label: "TBA_MBS", value: "TBA_MBS" },
    { label: "Mortgage Pool", value: "MortgagePool" },
];
export const FINANCIAL_SUBTYPES = FINANCIAL_SUBTYPE_OPTIONS.map((option) => option.value);
