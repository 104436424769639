/* eslint-disable no-use-before-define */
/// ////////////////////////////////////////////////////////
// Shared types
export const ExecutionOrderTypes = {
    Market: "Market",
    Limit: "Limit",
    Stop: "Stop",
    StopLimit: "StopLimit",
    MarketOnClose: "MarketOnClose",
};
export const FileSortOptions = {
    None: "None",
    Name: "Name",
    ModifiedAt: "ModifiedAt",
    CreatedAt: "CreatedAt",
};
export var PeriodCode;
(function (PeriodCode) {
    PeriodCode["Day"] = "D";
    PeriodCode["Week"] = "W";
    PeriodCode["Month"] = "M";
    PeriodCode["Year"] = "Y";
    PeriodCode["Term"] = "T";
    PeriodCode["Overnight"] = "ON";
    PeriodCode["TomorrowNext"] = "TN";
    PeriodCode["SpotNext"] = "SN";
    PeriodCode["SpotWeek"] = "SW";
})(PeriodCode || (PeriodCode = {}));
export var AssociationMode;
(function (AssociationMode) {
    AssociationMode["FundSpecific"] = "fundSpecific";
    AssociationMode["Firmwide"] = "firmWide";
})(AssociationMode || (AssociationMode = {}));
export var RestrictionType;
(function (RestrictionType) {
    RestrictionType["Exposure"] = "Exposure";
    RestrictionType["Restricted"] = "Restricted";
})(RestrictionType || (RestrictionType = {}));
export var Severity;
(function (Severity) {
    Severity["Warning"] = "Warning";
    Severity["Severe"] = "Severe";
    Severity["Override"] = "Override";
})(Severity || (Severity = {}));
