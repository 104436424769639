import { createTestId } from "@enfusion-ui/utils";
import { css, styled } from "@enfusion-ui/web-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
const StyledButton = styled.button `
  color: var(--text-normal);
  font: inherit;
  font-size: 0.875em;
  background: none;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  outline: none;
  text-align: left;

  ${({ hoverEffect = true }) => hoverEffect
    ? css `
          :hover {
            background-color: var(--background-color-0);
            transition: background-color 300ms;
          }
        `
    : ""}
`;
const selectIconColor = ({ icon, success, warning, danger, defaultColor, }) => {
    if (icon) {
        if (success)
            return "success";
        if (warning)
            return "warning";
        if (danger)
            return "danger";
    }
    return defaultColor ?? "primary";
};
const StyledTextButtonIcon = styled(FontAwesomeIcon) `
  ${({ labelPlacement }) => labelPlacement === "left" ? "margin-right: 8px;" : "margin-left: 8px;"}

  color: ${(props) => `var(--${selectIconColor(props)})`};
`;
export function TextButton({ icon, className, onClick, children, success, danger, warning, labelPlacement = "left", defaultColor, hoverEffect, ...rest }) {
    return (React.createElement(StyledButton, { className: className, onClick: onClick, hoverEffect: hoverEffect, ...rest },
        labelPlacement === "left" && icon && (React.createElement(StyledTextButtonIcon, { icon: icon, size: "sm", labelPlacement: labelPlacement, "data-testid": createTestId("icon"), success: success, danger: danger, warning: warning, defaultColor: defaultColor })),
        children,
        labelPlacement === "right" && icon && (React.createElement(StyledTextButtonIcon, { icon: icon, size: "sm", labelPlacement: labelPlacement, success: success, danger: danger, warning: warning, defaultColor: defaultColor }))));
}
